.loader {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  padding: 2em var(--site-padding);
  position: relative;
  width: 100%;
}
.scrollableContainer {
  max-height: 75vh; /* Limits height to viewport */
  overflow-y: auto; /* Enables vertical scrolling */
  position: relative;
}
.existButtun {
  /* margin: 0 15px; */
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #3D3E49;
  border-radius: 9px;
  opacity: 1;
  min-width: 89px;
  /* max-width: max-content; */
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  text-align: center;
  font-family: "Poppins", sans-serif !important;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0px;
  cursor: pointer;
  color: #282D33;
  opacity: 1;
  padding: 6.5px 16.5px;
}
.existButtun:hover {
  /* background: #579ce1 !important; */
  color: #579ce1;
}
.discard:hover {
  color: white;
}
.discard {
  color: #F74140;
  background: transparent;
  font-family: "Poppins", sans-serif !important;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
}
.accessedCompny {
  display: flex;
  background-color: #5AA5EF;
  width: 60%;
  gap: 20px;
  z-index: 111;
  cursor: pointer;
  position: fixed;
  height: 60px;
  align-items: center;
  justify-content: space-between;
  /* margin-top: -33px !important; */
  padding: 5px 23px;
  letter-spacing: 0px;
  opacity: 1;
}
@media only screen and (max-width: 899px) {
  .accessedCompny {
    top:64px;
  }
}
@media only screen and (min-width: 1710px) {
  .accessedCompny {
    top: 111px;
  }
}
@media only screen and (min-width: 900px) and (max-width: 1199px) {
  .accessedCompny {
    top:70px;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .accessedCompny {
    top:83.25px;
  }
}
@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .accessedCompny {
    top: 98.859375px;
  }
}
.visibility {
  margin: 0;
  font-size: 12px;
  font-family: "Poppins";
}
.loaderContent {
  display: inline;
  white-space: nowrap;
}

.page {
  background-color: #ffffff;
}

.pageContent {
  padding-left: 0 !important;
  padding-right: 0 !important;
  padding-top: 0 !important;
}

.removeButton {
  background-color: transparent;
  border: none;
  color: #716f6f;
  cursor: pointer;
  white-space: nowrap;
}
.removeButtonRuniing {
  background-color: transparent;
  border: none;
  color: red;
  cursor: default;
  white-space: nowrap;
}

@media all and (hover: hover) {
  .removeButton:hover {
    text-decoration: underline;
  }
}

@media all and (pointer: coarse) {
  .removeButton:active,
  .removeButton:target {
    text-decoration: underline;
  }
}

@media only screen and (min-width: 900px) and (max-width: 1199px) {
  .removeButtonRuniing,
  .removeButton {
    font-size: 11.5px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .removeButtonRuniing,
  .removeButton {
    font-size: 12px;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .removeButtonRuniing,
  .removeButton {
    font-size: 12px;
  }
}

@media only screen and (min-width: 1710px) {
  .removeButtonRuniing,
  .removeButton {
    font-size: 13px;
  }
}

.contentGroupTextContainer {
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: start;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

@media only screen and (max-width: 899px) {
  .contentGroupTextContainer {
    margin-left: 17.5px;
    max-width: 173px;
  }
  .accessedCompny {
    width: 100%;
    justify-content: start;
    bottom: 0;
    padding: 5px 13px;
    height: 40px;
  }
}

@media only screen and (min-width: 900px) and (max-width: 1199px) {
  .contentGroupTextContainer {
    margin-left: 17.5px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .contentGroupTextContainer {
    margin-left: 21px;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .contentGroupTextContainer {
    margin-left: 24.9375px;
  }
}

@media only screen and (min-width: 1710px) {
  .contentGroupTextContainer {
    margin-left: 28px;
  }
}

.contentGroupTitle::first-letter,
.contentGroupDescription::first-letter {
  text-transform: capitalize;
}

.contentGroupTitle {
  color: #02071a;
  font-weight: 600;
  line-height: 1.5;
  opacity: 0.72;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

@media only screen and (max-width: 899px) {
  .contentGroupTitle {
    font-size: 13px;
  }
}

@media only screen and (min-width: 900px) and (max-width: 1199px) {
  .contentGroupTitle {
    font-size: 13px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .contentGroupTitle {
    font-size: 14.25px;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .contentGroupTitle {
    font-size: 16.921875px;
  }
}

@media only screen and (min-width: 1710px) {
  .contentGroupTitle {
    font-size: 19px;
  }
}

.contentGroupDescription {
  color: #02071a;
  line-height: 1.2;
  opacity: 0.9;
}

@media only screen and (max-width: 899px) {
  .contentGroupDescription {
    font-size: 8px;
  }
}

@media only screen and (min-width: 900px) and (max-width: 1199px) {
  .contentGroupDescription {
    font-size: 8.75px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .contentGroupDescription {
    font-size: 10.5px;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .contentGroupDescription {
    font-size: 12.46875px;
  }
}

@media only screen and (min-width: 1710px) {
  .contentGroupDescription {
    font-size: 14px;
  }
}

.contentGroupTitle,
.contentGroupDescription {
  display: block;
  text-align: left;
  max-width: 400px;
}

.categoryContainer,
.categoryContainerImages {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.categoryContainer {
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.editIcon {
  cursor: pointer;
}

@media only screen and (max-width: 899px) {
  .editIcon {
    height: 24px;
    width: 24px;
  }
}

@media only screen and (min-width: 900px) {
  .editIcon {
    display: none;
  }
}

@media only screen and (max-width: 899px) {
  .contentGroupHeadCell {
    height: 38.12px !important;
    padding: 0 14px;
  }
}

@media only screen and (min-width: 900px) and (max-width: 1199px) {
  .contentGroupHeadCell {
    height: 40px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .contentGroupHeadCell {
    height: 48px;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .contentGroupHeadCell {
    height: 57px;
  }
}

@media only screen and (min-width: 1710px) {
  .contentGroupHeadCell {
    height: 64px;
  }
}

.contentGroupBodyRow {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: 0 var(--site-padding);
  position: relative;
  width: 100%;
}

.contentGroupBodyRow.dragged {
  -webkit-backdrop-filter: blur(30px);
  backdrop-filter: blur(30px);
  background-color: #f1f1f1aa;
  border-radius: 4px;
  -webkit-box-shadow: rgba(0, 0, 0, 0.2) 0 0 10px 0px;
  box-shadow: rgba(0, 0, 0, 0.2) 0 0 10px 0px;
}

.contentGroupBodyRow:not(.dragged)::before {
  border-bottom: 1px solid rgba(112, 112, 112, 0.18);
  content: " ";
  display: block;
  left: var(--site-padding);
  position: absolute;
  top: 100%;
  width: calc(100% - (2 * var(--site-padding)));
}

@media only screen and (max-width: 899px) {
  .contentGroupBodyCell:nth-child(1) {
    padding-left: 0 !important;
  }
}

@media only screen and (max-width: 899px) {
  .contentGroupHeadCell:nth-child(2),
  .contentGroupBodyCell:nth-child(2) {
    display: none;
  }
}

.contentGroupBodyCell {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

@media only screen and (max-width: 899px) {
  .contentGroupBodyCell {
    height: 130px;
    padding: 0 14px;
  }
}

@media only screen and (min-width: 900px) and (max-width: 1199px) {
  .contentGroupBodyCell {
    height: 130px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .contentGroupBodyCell {
    height: 143.25px;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .contentGroupBodyCell {
    height: 170.109375px;
  }
}

@media only screen and (min-width: 1710px) {
  .contentGroupBodyCell {
    height: 191px;
  }
}

.dragIconContainer {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

@media only screen and (max-width: 899px) {
  .dragIconContainer {
    margin-right: 15px;
  }
}

@media only screen and (min-width: 900px) and (max-width: 1199px) {
  .dragIconContainer {
    margin-right: 20.83125px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .dragIconContainer {
    margin-right: 24.9975px;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .dragIconContainer {
    margin-right: 29.68453125px;
  }
}

@media only screen and (min-width: 1710px) {
  .dragIconContainer {
    margin-right: 33.33px;
  }
}

.dragIcon {
  color: #a0a0a0;
}

@media only screen and (max-width: 899px) {
  .dragIcon {
    height: 15px;
    width: 15px;
  }
}

@media only screen and (min-width: 900px) and (max-width: 1199px) {
  .dragIcon {
    height: 20.83125px;
    width: 20.83125px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .dragIcon {
    height: 24.9975px;
    width: 24.9975px;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .dragIcon {
    height: 29.68453125px;
    width: 29.68453125px;
  }
}

@media only screen and (min-width: 1710px) {
  .dragIcon {
    height: 33.33px;
    width: 33.33px;
  }
}

.pageContent {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding-bottom: 0;
}

.pageContent > * {
  -ms-flex-negative: 0;
  flex-shrink: 0;
}

@media only screen and (max-width: 899px) {
  .pageContent {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }
}

.preview {
  background-color: #f9f9f9;
}
.header {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: var(--header-height);
}
.videoBackButton {
  cursor: pointer;
  margin-left: -14px;
  position: relative;
  vertical-align: middle;
  margin-top: -23px;
  margin-right: -40px;
  margin-left: -34px;
}

@media only screen and (min-width: 900px) {
  .header {
    display: none;
  }
}
@media only screen and (min-width: 900px) {
  .preview {
    max-height: calc(100vh - var(--header-height));
    overflow-y: auto;
    position: -webkit-sticky;
    position: sticky;
    top: var(--header-height);
    width: 40%;
  }
}

@supports (padding: max(0px)) {
  @media only screen and (max-width: 319px) {
    .preview {
      padding-top: max(12px, env(safe-area-inset-top));
    }
  }
  @media only screen and (min-width: 320px) and (max-width: 419px) {
    .preview {
      padding-top: max(16px, env(safe-area-inset-top));
    }
  }
  @media only screen and (min-width: 420px) and (max-width: 767px) {
    .preview {
      padding-top: max(20px, env(safe-area-inset-top));
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 899px) {
    .preview {
      padding-top: max(24px, env(safe-area-inset-top));
    }
  }
  @media only screen and (min-width: 900px) and (max-width: 1199px) {
    .preview {
      padding-top: max(28px, env(safe-area-inset-top));
    }
  }
  @media only screen and (min-width: 1200px) and (max-width: 1440px) {
    .preview {
      padding-top: max(32px, env(safe-area-inset-top));
    }
  }
  @media only screen and (min-width: 1441px) and (max-width: 1709px) {
    .preview {
      padding-top: max(36px, env(safe-area-inset-top));
    }
  }
  @media only screen and (min-width: 1710px) {
    .preview {
      padding-top: max(40px, env(safe-area-inset-top));
    }
  }
}

@supports not (padding: max(0px)) {
  @media only screen and (max-width: 319px) {
    .preview {
      padding-top: 12px;
    }
  }
  @media only screen and (min-width: 320px) and (max-width: 419px) {
    .preview {
      padding-top: 16px;
    }
  }
  @media only screen and (min-width: 420px) and (max-width: 767px) {
    .preview {
      padding-top: 20px;
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 899px) {
    .preview {
      padding-top: 24px;
    }
  }
  @media only screen and (min-width: 900px) and (max-width: 1199px) {
    .preview {
      padding-top: 28px;
    }
  }
  @media only screen and (min-width: 1200px) and (max-width: 1440px) {
    .preview {
      padding-top: 32px;
    }
  }
  @media only screen and (min-width: 1441px) and (max-width: 1709px) {
    .preview {
      padding-top: 36px;
    }
  }
  @media only screen and (min-width: 1710px) {
    .preview {
      padding-top: 40px;
    }
  }
}

@supports (padding: max(0px)) {
  @media only screen and (max-width: 319px) {
    .preview {
      padding-bottom: max(12px, env(safe-area-inset-bottom));
    }
  }
  @media only screen and (min-width: 320px) and (max-width: 419px) {
    .preview {
      padding-bottom: max(16px, env(safe-area-inset-bottom));
    }
  }
  @media only screen and (min-width: 420px) and (max-width: 767px) {
    .preview {
      padding-bottom: max(20px, env(safe-area-inset-bottom));
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 899px) {
    .preview {
      padding-bottom: max(24px, env(safe-area-inset-bottom));
    }
  }
  @media only screen and (min-width: 900px) and (max-width: 1199px) {
    .preview {
      padding-bottom: max(28px, env(safe-area-inset-bottom));
    }
  }
  @media only screen and (min-width: 1200px) and (max-width: 1440px) {
    .preview {
      padding-bottom: max(32px, env(safe-area-inset-bottom));
    }
  }
  @media only screen and (min-width: 1441px) and (max-width: 1709px) {
    .preview {
      padding-bottom: max(36px, env(safe-area-inset-bottom));
    }
  }
  @media only screen and (min-width: 1710px) {
    .preview {
      padding-bottom: max(40px, env(safe-area-inset-bottom));
    }
  }
}

@supports not (padding: max(0px)) {
  @media only screen and (max-width: 319px) {
    .preview {
      padding-bottom: 12px;
    }
  }
  @media only screen and (min-width: 320px) and (max-width: 419px) {
    .preview {
      padding-bottom: 16px;
    }
  }
  @media only screen and (min-width: 420px) and (max-width: 767px) {
    .preview {
      padding-bottom: 20px;
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 899px) {
    .preview {
      padding-bottom: 24px;
    }
  }
  @media only screen and (min-width: 900px) and (max-width: 1199px) {
    .preview {
      padding-bottom: 28px;
    }
  }
  @media only screen and (min-width: 1200px) and (max-width: 1440px) {
    .preview {
      padding-bottom: 32px;
    }
  }
  @media only screen and (min-width: 1441px) and (max-width: 1709px) {
    .preview {
      padding-bottom: 36px;
    }
  }
  @media only screen and (min-width: 1710px) {
    .preview {
      padding-bottom: 40px;
    }
  }
}

@supports (padding: max(0px)) {
  @media only screen and (max-width: 319px) {
    .preview {
      padding-left: max(12px, env(safe-area-inset-left)) !important;
    }
  }
  @media only screen and (min-width: 320px) and (max-width: 419px) {
    .preview {
      padding-left: max(16px, env(safe-area-inset-left)) !important;
    }
  }
  @media only screen and (min-width: 420px) and (max-width: 767px) {
    .preview {
      padding-left: max(20px, env(safe-area-inset-left)) !important;
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 899px) {
    .preview {
      padding-left: max(24px, env(safe-area-inset-left)) !important;
    }
  }
  @media only screen and (min-width: 900px) and (max-width: 1199px) {
    .preview {
      padding-left: max(
        calc(2 * var(--site-padding)),
        env(safe-area-inset-left)
      ) !important;
    }
  }
  @media only screen and (min-width: 1200px) and (max-width: 1440px) {
    .preview {
      padding-left: max(
        calc(2 * var(--site-padding)),
        env(safe-area-inset-left)
      ) !important;
    }
  }
  @media only screen and (min-width: 1441px) and (max-width: 1709px) {
    .preview {
      padding-left: max(
        calc(2 * var(--site-padding)),
        env(safe-area-inset-left)
      ) !important;
    }
  }
  @media only screen and (min-width: 1710px) {
    .preview {
      padding-left: max(
        calc(2 * var(--site-padding)),
        env(safe-area-inset-left)
      ) !important;
    }
  }
}

@supports not (padding: max(0px)) {
  @media only screen and (max-width: 319px) {
    .preview {
      padding-left: 12px !important;
    }
  }
  @media only screen and (min-width: 320px) and (max-width: 419px) {
    .preview {
      padding-left: 16px !important;
    }
  }
  @media only screen and (min-width: 420px) and (max-width: 767px) {
    .preview {
      padding-left: 20px !important;
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 899px) {
    .preview {
      padding-left: 24px !important;
    }
  }
  @media only screen and (min-width: 900px) and (max-width: 1199px) {
    .preview {
      padding-left: calc(2 * var(--site-padding)) !important;
    }
  }
  @media only screen and (min-width: 1200px) and (max-width: 1440px) {
    .preview {
      padding-left: calc(2 * var(--site-padding)) !important;
    }
  }
  @media only screen and (min-width: 1441px) and (max-width: 1709px) {
    .preview {
      padding-left: calc(2 * var(--site-padding)) !important;
    }
  }
  @media only screen and (min-width: 1710px) {
    .preview {
      padding-left: calc(2 * var(--site-padding)) !important;
    }
  }
}

@supports (padding: max(0px)) {
  @media only screen and (max-width: 319px) {
    .preview {
      padding-right: max(12px, env(safe-area-inset-right)) !important;
    }
  }
  @media only screen and (min-width: 320px) and (max-width: 419px) {
    .preview {
      padding-right: max(16px, env(safe-area-inset-right)) !important;
    }
  }
  @media only screen and (min-width: 420px) and (max-width: 767px) {
    .preview {
      padding-right: max(20px, env(safe-area-inset-right)) !important;
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 899px) {
    .preview {
      padding-right: max(24px, env(safe-area-inset-right)) !important;
    }
  }
  @media only screen and (min-width: 900px) and (max-width: 1199px) {
    .preview {
      padding-right: max(
        calc(2 * var(--site-padding)),
        env(safe-area-inset-right)
      ) !important;
    }
  }
  @media only screen and (min-width: 1200px) and (max-width: 1440px) {
    .preview {
      padding-right: max(
        calc(2 * var(--site-padding)),
        env(safe-area-inset-right)
      ) !important;
    }
  }
  @media only screen and (min-width: 1441px) and (max-width: 1709px) {
    .preview {
      padding-right: max(
        calc(2 * var(--site-padding)),
        env(safe-area-inset-right)
      ) !important;
    }
  }
  @media only screen and (min-width: 1710px) {
    .preview {
      padding-right: max(
        calc(2 * var(--site-padding)),
        env(safe-area-inset-right)
      ) !important;
    }
  }
}

@supports not (padding: max(0px)) {
  @media only screen and (max-width: 319px) {
    .preview {
      padding-right: 12px !important;
    }
  }
  @media only screen and (min-width: 320px) and (max-width: 419px) {
    .preview {
      padding-right: 16px !important;
    }
  }
  @media only screen and (min-width: 420px) and (max-width: 767px) {
    .preview {
      padding-right: 20px !important;
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 899px) {
    .preview {
      padding-right: 24px !important;
    }
  }
  @media only screen and (min-width: 900px) and (max-width: 1199px) {
    .preview {
      padding-right: calc(2 * var(--site-padding)) !important;
    }
  }
  @media only screen and (min-width: 1200px) and (max-width: 1440px) {
    .preview {
      padding-right: calc(2 * var(--site-padding)) !important;
    }
  }
  @media only screen and (min-width: 1441px) and (max-width: 1709px) {
    .preview {
      padding-right: calc(2 * var(--site-padding)) !important;
    }
  }
  @media only screen and (min-width: 1710px) {
    .preview {
      padding-right: calc(2 * var(--site-padding)) !important;
    }
  }
}

.previewImage {
  background-color: #ffffff;
  border: 1px solid #707070;
  margin-bottom: calc(var(--site-padding) / 2);
  /* padding-top: 60%; */
  width: 100%;
}

.previewTitleLoading {
  color: #000000;
  margin-right: 0.5em;
}

@media only screen and (max-width: 899px) {
  .previewTitleLoading {
    font-size: 22px;
  }
}

@media only screen and (min-width: 900px) and (max-width: 1440px) {
  .previewTitleLoading {
    font-size: 25.5px;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .previewTitleLoading {
    font-size: 30.28125px;
  }
}

@media only screen and (min-width: 1710px) {
  .previewTitleLoading {
    font-size: 34px;
  }
}

.previewTitle {
  background-color: transparent;
  border: 2px solid transparent;
  color: #000000;
  font-family: "Poppins", sans-serif;
  margin: 0;
  margin-right: 0.5em;
  -webkit-transition-duration: 150ms;
  transition-duration: 150ms;
  width: 100%;
  font-weight: 600;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.previewTitle:not(:disabled) {
  background-color: #fff;
  border-color: #007aff !important;
  border-radius: 0.25em;
  padding-left: 0.5em;
  padding-right: 0.5em;
}

.previewTitle:disabled {
  color: #000000;
}

@media only screen and (max-width: 899px) {
  .previewTitle {
    font-size: 22px;
  }
}

@media only screen and (min-width: 900px) and (max-width: 1440px) {
  .previewTitle {
    font-size: 22.5px;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .previewTitle {
    font-size: 30.28125px;
  }
}

@media only screen and (min-width: 1710px) {
  .previewTitle {
    font-size: 34px;
  }
}

.previewDetails {
  color: #818181;
  margin-top: calc(var(--site-padding) / 2.5);
}

@media only screen and (max-width: 899px) {
  .previewDetails {
    font-size: 11px;
  }
}

@media only screen and (min-width: 900px) and (max-width: 1440px) {
  .previewDetails {
    font-size: 11px;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .previewDetails {
    font-size: 15.140625px;
  }
}

@media only screen and (min-width: 1710px) {
  .previewDetails {
    font-size: 17px;
  }
}

.contentGroupContainer {
  background-color: #f1f1f1;
}

@media only screen and (max-width: 899px) {
  .contentGroupContainer {
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
  }
}

@media only screen and (min-width: 900px) {
  .contentGroupContainer {
    width: 60%;
  }
}

@supports (padding: max(0px)) {
  @media only screen and (max-width: 319px) {
    .contentGroupContainer {
      padding-top: max(12px, env(safe-area-inset-top));
    }
  }
  @media only screen and (min-width: 320px) and (max-width: 419px) {
    .contentGroupContainer {
      padding-top: max(16px, env(safe-area-inset-top));
    }
  }
  @media only screen and (min-width: 420px) and (max-width: 767px) {
    .contentGroupContainer {
      padding-top: max(20px, env(safe-area-inset-top));
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 899px) {
    .contentGroupContainer {
      padding-top: max(24px, env(safe-area-inset-top));
    }
  }
  @media only screen and (min-width: 900px) and (max-width: 1199px) {
    .contentGroupContainer {
      padding-top: max(28px, env(safe-area-inset-top));
    }
  }
  @media only screen and (min-width: 1200px) and (max-width: 1440px) {
    .contentGroupContainer {
      padding-top: max(32px, env(safe-area-inset-top));
    }
  }
  @media only screen and (min-width: 1441px) and (max-width: 1709px) {
    .contentGroupContainer {
      padding-top: max(36px, env(safe-area-inset-top));
    }
  }
  @media only screen and (min-width: 1710px) {
    .contentGroupContainer {
      padding-top: max(40px, env(safe-area-inset-top));
    }
  }
}

@supports not (padding: max(0px)) {
  @media only screen and (max-width: 319px) {
    .contentGroupContainer {
      padding-top: 12px;
    }
  }
  @media only screen and (min-width: 320px) and (max-width: 419px) {
    .contentGroupContainer {
      padding-top: 16px;
    }
  }
  @media only screen and (min-width: 420px) and (max-width: 767px) {
    .contentGroupContainer {
      padding-top: 20px;
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 899px) {
    .contentGroupContainer {
      padding-top: 24px;
    }
  }
  @media only screen and (min-width: 900px) and (max-width: 1199px) {
    .contentGroupContainer {
      padding-top: 28px;
    }
  }
  @media only screen and (min-width: 1200px) and (max-width: 1440px) {
    .contentGroupContainer {
      padding-top: 32px;
    }
  }
  @media only screen and (min-width: 1441px) and (max-width: 1709px) {
    .contentGroupContainer {
      padding-top: 36px;
    }
  }
  @media only screen and (min-width: 1710px) {
    .contentGroupContainer {
      padding-top: 40px;
    }
  }
}

@supports (padding: max(0px)) {
  @media only screen and (max-width: 319px) {
    .contentGroupContainer {
      padding-bottom: max(12px, env(safe-area-inset-bottom));
    }
  }
  @media only screen and (min-width: 320px) and (max-width: 419px) {
    .contentGroupContainer {
      padding-bottom: max(16px, env(safe-area-inset-bottom));
    }
  }
  @media only screen and (min-width: 420px) and (max-width: 767px) {
    .contentGroupContainer {
      padding-bottom: max(20px, env(safe-area-inset-bottom));
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 899px) {
    .contentGroupContainer {
      padding-bottom: max(24px, env(safe-area-inset-bottom));
    }
  }
  @media only screen and (min-width: 900px) and (max-width: 1199px) {
    .contentGroupContainer {
      padding-bottom: max(28px, env(safe-area-inset-bottom));
    }
  }
  @media only screen and (min-width: 1200px) and (max-width: 1440px) {
    .contentGroupContainer {
      padding-bottom: max(32px, env(safe-area-inset-bottom));
    }
  }
  @media only screen and (min-width: 1441px) and (max-width: 1709px) {
    .contentGroupContainer {
      padding-bottom: max(36px, env(safe-area-inset-bottom));
    }
  }
  @media only screen and (min-width: 1710px) {
    .contentGroupContainer {
      padding-bottom: max(40px, env(safe-area-inset-bottom));
    }
  }
}

@supports not (padding: max(0px)) {
  @media only screen and (max-width: 319px) {
    .contentGroupContainer {
      padding-bottom: 12px;
    }
  }
  @media only screen and (min-width: 320px) and (max-width: 419px) {
    .contentGroupContainer {
      padding-bottom: 16px;
    }
  }
  @media only screen and (min-width: 420px) and (max-width: 767px) {
    .contentGroupContainer {
      padding-bottom: 20px;
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 899px) {
    .contentGroupContainer {
      padding-bottom: 24px;
    }
  }
  @media only screen and (min-width: 900px) and (max-width: 1199px) {
    .contentGroupContainer {
      padding-bottom: 28px;
    }
  }
  @media only screen and (min-width: 1200px) and (max-width: 1440px) {
    .contentGroupContainer {
      padding-bottom: 32px;
    }
  }
  @media only screen and (min-width: 1441px) and (max-width: 1709px) {
    .contentGroupContainer {
      padding-bottom: 36px;
    }
  }
  @media only screen and (min-width: 1710px) {
    .contentGroupContainer {
      padding-bottom: 40px;
    }
  }
}

.contentGroup {
  border-collapse: collapse;
  width: 100%;
  position: relative;
}

.contentGroupText {
  font-weight: 600;
}

@media only screen and (max-width: 1199px) {
  .contentGroupText {
    font-size: 15px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .contentGroupText {
    font-size: 18px;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .contentGroupText {
    font-size: 21.375px;
  }
}

@media only screen and (min-width: 1710px) {
  .contentGroupText {
    font-size: 24px;
  }
}

.imageContainer {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  position: relative;
}

@media only screen and (max-width: 1199px) {
  .imageContainer {
    height: 71px;
    min-width: 132px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .imageContainer {
    height: 80.25px;
    min-width: 150px;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .imageContainer {
    height: 95.296875px;
    min-width: 178.125px;
  }
}

@media only screen and (min-width: 1710px) {
  .imageContainer {
    height: 107px;
    min-width: 200px;
  }
}

.image {
  background-color: #eee;
  border: 1px solid #707070;
  border-radius: 5px;
  height: 100%;
  left: 0;
  -o-object-fit: cover;
  object-fit: cover;
  -o-object-position: center;
  object-position: center;
  position: absolute;
  top: 0;
  width: 100%;
}

.previewInputContainer {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  margin-top: 4%;
  width: 100%;
}

.previewIcon {
  color: #818181;
  cursor: pointer;
  position: relative;
}

.backButton {
  margin: auto 20px;
  font-size: 23px;
  color: #5983c3;
  font-weight: 600;
  cursor: pointer;
  display: inline-flex;
}

@media only screen and (max-width: 899px) {
  .previewIcon {
    font-size: 22px;
    height: 22px;
    width: 22px;
  }
  .backButton {
    display: none;
  }
  .positionStyle {
    margin-top: 14px;
    margin-left: -23px;
  }
}

@media only screen and (min-width: 900px) and (max-width: 1440px) {
  .previewIcon {
    font-size: 25.5px;
    height: 25.5px;
    width: 25.5px;
  }
  .backButton {
    font-size: 18px;
    margin-left: -52px;
    margin-right: -4px;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .previewIcon {
    font-size: 30.28125px;
    height: 30.28125px;
    width: 30.28125px;
  }
  .backButton {
    font-size: 18px;
    margin-left: -62px;
    margin-right: 4px;
  }
}

@media only screen and (min-width: 1710px) {
  .previewIcon {
    font-size: 34px;
    height: 34px;
    width: 34px;
  }
  .backButton {
    font-size: 20px;
    margin-left: -62px;
    margin-right: -5px;
  }
}

.dataText {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  padding: 2em;
}

.body {
  background-color: #ffffff;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  width: 100%;
}

.headerContent {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  border-bottom-color: rgba(112, 112, 112, 0.15);
  border-bottom-style: solid;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.headerText {
  font-weight: 600;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.header {
  -webkit-box-flex: 0;
  -ms-flex-positive: 0;
  flex-grow: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
}

.content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

@media only screen and (min-width: 768px) {
  .body {
    width: calc(
      100vw - (var(--header-height) * 2) - ((var(--header-height) / 8) * 2)
    );
  }
}

@media only screen and (min-width: 900px) {
  .body {
    height: calc(
      100vh - (var(--header-height) * 2) - ((var(--header-height) / 8) * 2)
    ) !important;
  }
}

@media only screen and (max-width: 767px) {
  .body {
    height: 100vh !important;
  }
  .header {
    padding: 24px 24px 0 24px !important;
  }
  .headerContent {
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    border-bottom-width: 0px;
    padding-bottom: 28px;
  }
  .headerText {
    color: #fff;
    font-size: 21px;
    font-weight: 500;
  }
  .content {
    padding: 0 24px !important;
  }
}

@media only screen and (min-width: 768px) and (max-width: 899px) {
  .body {
    --site-padding: 30.703124999999996px;
    border-radius: 5px;
    height: calc(100vh - (var(--header-height))) !important;
    max-width: 1033.5px;
  }
  .header {
    padding: 19.21875px 30.703124999999996px 0 30.703124999999996px !important;
  }
  .headerContent {
    border-bottom-width: 1.78125px;
    padding-bottom: 11.5px;
  }
  .headerText {
    font-size: 18.75px;
  }
}

@media only screen and (min-width: 900px) and (max-width: 1440px) {
  .body {
    --site-padding: 49.12499999999999px;
    border-radius: 7.5px;
    max-width: 1033.5px;
  }
  .header {
    padding: 30.75px 49.12499999999999px 0 49.12499999999999px !important;
  }
  .headerContent {
    border-bottom-width: 1.78125px;
    padding-bottom: 11.5px;
  }
  .headerText {
    font-size: 22.5px;
  }
  .content {
    padding: 23px 49.125px !important;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .body {
    --site-padding: 58.33593749999999px;
    border-radius: 8.90625px;
    max-width: 1227.28125px;
  }
  .header {
    padding: 36.515625px 58.33593749999999px 0 58.33593749999999px !important;
  }
  .headerContent {
    border-bottom-width: 1.78125px;
    padding-bottom: 11.5px;
  }
  .headerText {
    font-size: 26.71875px;
  }
  .content {
    padding: 23px 58.33594px !important;
  }
}

@media only screen and (min-width: 1710px) {
  .body {
    --site-padding: 65.5px;
    border-radius: 10px;
    max-width: 1378px;
  }
  .header {
    padding: 41px 65.5px 0 65.5px !important;
  }
  .headerContent {
    border-bottom-width: 2px;
    padding-bottom: 11.5px;
  }
  .headerText {
    font-size: 30px;
  }
}

@media only screen and (max-height: 568px) {
  .body {
    border-radius: 0 !important;
    height: 100vh !important;
    max-height: 100vh !important;
    max-width: 100vw !important;
    width: 100vw !important;
  }
}

.addMorePhotoContainer {
  margin-top: 44px;
  cursor: pointer;
}
.addMorePhoto {
  vertical-align: middle;
  color: #02071a;
  font-weight: 600;
  font-family: "Poppins";
  font-size: 15px;
  margin-left: 6px;
}
.addMoreIcon {
  vertical-align: middle;
  cursor: pointer !important;
}

.addMorePhotoIcon {
  vertical-align: middle;
  cursor: pointer !important;
  margin-right: 7px !important;
  position: relative;
  bottom: 2px;
}

.editBackground {
  float: right;
  font-size: 18px;
  color: #818181;
  cursor: pointer;
}

.backgroundContainer {
  width: 388px;
  height: 300px;
}

.backClose {
  float: right;
  cursor: pointer;
}

.backgroundImageList {
  display: table-row;
  vertical-align: middle;
  position: absolute;
  margin: 38px 92px;
  width: 283px;
  max-height: 230px;
  overflow-y: scroll;
  overflow-x: hidden;
}

.backgroundImageList::-webkit-scrollbar {
  width: 10px;
}

/* Track */
.backgroundImageList::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
.backgroundImageList::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 25px;
}

/* Handle on hover */
.backgroundImageList::-webkit-scrollbar-thumb:hover {
  background: #555;
}

#background-menu {
  overflow: hidden;
}

.changeButton {
  border-color: #5aa5ef;
  border-width: 2px;
  border-style: solid;
  text-align: center;
  position: absolute;
  bottom: 0px;
  width: 174px;
  left: 93px;
  margin: 7px auto;
  cursor: pointer;
}

.backGroundImage:active {
  border-color: #5aa5ef;
}

.backgroundPreview {
  border-radius: 2vh;
  background-color: #2f303d;
  vertical-align: middle;
  align-items: center;
  display: flex;
  justify-content: center;
  font-size: 30px;
  margin-top: 53px;
  color: white;
  margin-bottom: 30px;
  width: 470px;
  height: 115px;
}

.backTitleContainer {
  display: inline-flex;
}
#background-menu {
  top: 388px;
}
.previewImageBack {
  /* background-color: #ffffff; */
  border: 1px solid #707070;
  margin-bottom: calc(var(--site-padding) / 7);
  width: 100%;
  height: 168px;
  display: inline-grid;
  align-items: center;
  text-align: center;
  color: #fff;
  font-size: 34px;
  margin-top: 4%;
  margin-bottom: 3%;
  border-radius: 3vh;
}

@media only screen and (max-height: 780px) {
  .backgroundContainer {
    height: 192px;
  }
  .backgroundImageList {
    max-height: 149px;
  }
}

.slideShowTransitions {
  -webkit-appearance: listbox !important ;
  margin-left: 20px;
  width: 120px;
}

.albumSettingsDiv {
  margin-top: 30px;
  display: flex;
  flex-direction: row;
}

.saveButtonForSettings {
  display: grid;
  background-color: #5aa5ef;
  max-height: 20px;
  font-size: 10px;
  max-width: 90px;
  min-width: 80px;
  color: #ffffff;
  text-align: center;
  border-radius: 2px;
  margin-top: 5px;
  vertical-align: middle;
  align-items: center;
  margin-left: 20px;
}

.selectTransition {
  display: flex;
  flex-direction: row;
  width: 50%;
}

.saveButtonForSettingDisabled {
  opacity: 0.6;
  cursor: not-allowed;
}

.dropDownAnimation {
  animation: growOut 700ms ease-in-out forwards;
  transform-origin: top center;
}

@keyframes growOut {
  0% {
    transform: scale(0);
  }
  80% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}
